var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "physicalRegisterUpload",
      attrs: { title: _vm.modalTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "excel" } },
        [
          _vm.tablePersonData && _vm.tablePersonData.length > 0
            ? _c(
                "Row",
                [
                  _c(
                    "vxe-table",
                    {
                      ref: "tabsValue",
                      attrs: {
                        border: "",
                        resizable: "",
                        data: _vm.tablePersonData,
                        "max-height": 400,
                      },
                    },
                    [
                      _c("vxe-column", {
                        attrs: {
                          field: "personName",
                          title: "姓名",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "idCard",
                          title: "证件号",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "mobile",
                          title: "手机号",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "hazardFactorsText",
                          title: "危害因素",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "otherHazardFactors",
                          title: "其他危害因素",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "workTypeText",
                          title: "工种名称",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "workName",
                          title: "其他工种名称",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "workStateText",
                          title: "在岗状态",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "department",
                          title: "部门",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "Row",
                { staticClass: "margin-top-10" },
                [
                  _c("Table", {
                    attrs: {
                      columns: _vm.tableTitle,
                      data: _vm.tableData,
                      loading: _vm.tableLoading,
                      "max-height": "400",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            { attrs: { type: "text" }, on: { click: _vm.closeAllModal } },
            [_vm._v("取消")]
          ),
          _vm.tablePersonData && _vm.tablePersonData.length > 0
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.uploadLoading },
                  on: { click: _vm.exportData },
                },
                [_vm._v("导出")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }